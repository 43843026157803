<template>
  <section>
    <a-row class="search-container" type="flex" justify="space-between">
      <a-form
        :form="serviveForm"
        layout="inline"
        @submit="submitServiveForm"
        class="m-t-14"
      >
        <a-form-item label="设备名称">
          <a-input
            autocomplete="off"
            v-decorator="['deviceName']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="任务状态">
          <a-select
            class="search-select"
            placeholder="请选择"
            v-decorator="['taskStatus']"
          >
            <a-select-option value="0"> 待处理 </a-select-option>
            <a-select-option value="1"> 待验收 </a-select-option>
            <a-select-option value="2"> 已完成 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="发起时间">
          <a-range-picker v-model="sponsorTime">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit"
            >查询</a-button
          >
          <a-button @click="reset" class="m-r-8">重置</a-button>
          <!-- <a-button>添加维保单</a-button> -->
        </a-form-item>
      </a-form>

      <a-button type="primary" class="m-t-18" @click="addService"
        >添加维保单</a-button
      >
    </a-row>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        :columns="list.columns"
        @change="changePageNum"
        :pagination="{
          showQuickJumper: false,
          total: list.total,
          current: list.pageNum,
        }"
      >
        <template slot="operation" slot-scope="list, text">
          <a style="color: #529aff" @click="checkDeviceStatus(text)">查看</a>
        </template>
        <template slot="taskStatus" slot-scope="list, text">
          <span>{{
            text.taskStatus == "0"
              ? "待处理"
              : text.taskStatus == "1"
              ? "待验收"
              : "已完成"
          }}</span>
        </template>
      </a-table>
    </section>
    <!-- 添加维保单 -->
    <a-drawer
      class="drawer_module"
      title="添加维保单"
      placement="right"
      :visible="addServiceVisible"
      :width="350"
      :destroyOnClose="true"
      @close="onClose"
      :maskClosable="true"
    >
      <a-form :form="addserviveForm">
        <a-form-item
          label="设备名称"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 16 }"
          class="m-b-18"
        >
          <a-select
            v-decorator="[
              'deviceId',
              { rules: [{ required: true, message: '请选择设备名称' }] },
            ]"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in deviceData"
              :key="item.id"
              :value="item.id"
              @click="getDeviceData(item)"
            >
              {{ item.deviceName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="维保负责人"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-row type="flex" justify="space-between">
            <a-input
              style="width: 60%"
              v-decorator="[
                'maintainerName',
                {
                  rules: [{ required: true, message: '请选择维保负责人' }],
                },
              ]"
              autocomplete="off"
            />
            <a-button type="primary" size="small" @click="selectPerson('1')"
              >选择</a-button
            >
          </a-row>
        </a-form-item>

        <a-form-item
          label="联系方式"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 16 }"
        >
          <!-- <a-select v-decorator="['username']" placeholder="请选择"></a-select> -->
          <a-input
            :disabled="true"
            v-decorator="['maintainerPhone']"
            autocomplete="off"
          ></a-input>
        </a-form-item>

        <a-form-item
          label="验收负责人"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-row type="flex" justify="space-between">
            <a-input
              style="width: 60%"
              v-decorator="['acceptorName']"
              :disabled="true"
              autocomplete="off"
            />
            <a-button type="primary" size="small" @click="selectPerson('2')"
              >选择</a-button
            >
          </a-row>
        </a-form-item>
        <a-form-item
          label="联系方式"
          :label-col="{ span: 7 }"
                :wrapper-col="{ span: 16 }"
        >
          <!-- <a-select v-decorator="['username']" placeholder="请选择"></a-select> -->
          <a-input
            :disabled="true"
            v-decorator="['acceptorPhone']"
            autocomplete="off"
          ></a-input>
        </a-form-item>

        <a-form-item
          label="问题照片"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-row type="flex">
            <upload
              style="display: inline-block; width: 100px"
              v-decorator="[
                'faultPhotoUrl',
                { rules: [{ required: false, message: '请上传照片' }] },
              ]"
              :node="{ type: 2, text: '上传' }"
              :accept="['.png', '.jpg']"
              :uploadUp="uploadUpMore"
              :loadData="loadDataArr"
              @func="uploadFile"
            />
          </a-row>
        </a-form-item>

        <a-row class="fileList" style="padding: 0px 20px">
          <a v-if="files.length > 0" class="file-item m-r-10">
            <span
              v-for="(item, index) in files"
              :key="index"
              class="m-r-4"
              style="position: relative; display: inline-block"
            >
              <a-icon class="delFile" type="close" @click="delFile(item.url)" />
              <a-avatar shape="square" :size="40" :src="item.url" />
            </span>
            <!-- <a-avatar shape="square" :size="100" :src="files.url" /> -->
          </a>
        </a-row>
        <a-form-item
          label="问题说明"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 16 }"
        >
          <!-- <a-input
            style="width: 100%"
            v-decorator="[
              'faultDescription',
              {
                rules: [
                  { required: false },
                  { max: 200, message: '最大输入200字符' },
                ],
              },
            ]"
            autocomplete="off"
            placeholder="请输入"
          /> -->

          <a-textarea
            style="height: 80px"
            v-decorator="[
              'faultDescription',
              {
                rules: [
                  { required: false, message: '请输入问题说明' },
                  { max: 200, message: '最大输入200个字符' },
                ],
              },
            ]"
            placeholder="请输入"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: '10px',
          bottom: '20px',
          width: '100%',
          padding: '10px 16px',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          :style="{
            marginRight: '8px',
            backgroundColor: '#3f72ba',
            border: 'none',
          }"
          type="primary"
          @click="saveServiveForm"
        >
          提交
        </a-button>
        <a-button
          :style="{
            marginRight: '8px',
            backgroundColor: '#1c3d6c',
            color: '#fff',

            border: 'none',
          }"
          @click="close"
        >
          关闭
        </a-button>
      </div>
    </a-drawer>

    <!-- 处理  验收 完成保单 -->

    <a-drawer
      :closable="true"
      class="drawer_module"
      :title="drawerTitle"
      placement="right"
      :visible="deviceVisible"
      :width="350"
      @close="onClose"
      :destroyOnClose="true"
      :maskClosable="true"
    >
      <span v-if="drawerTab != 2" class="process" style="color: #4b80cb">
        <a-badge :dot="show">
          <span style="cursor: pointer" @click="checkProcess">进度详情</span>
        </a-badge>
      </span>
      <!-- 进度状态 -->
      <section v-if="drawerTab == '1'">
        <section class="deviceData">
          <a-row class="m-b-10" type="flex" justify="end">
            <a-col :span="4" style="color: #fff">
              <span>{{ statusTitle }}</span>
            </a-col>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 设备名称 </a-col>
            <span>{{ detailData.deviceName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 发起人 </a-col>
            <span>{{ detailData.customerName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 联系方式 </a-col>
            <span>{{ detailData.customerPhone }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 维保负责人 </a-col>
            <span>{{ detailData.maintainerName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 联系方式 </a-col>
            <span>{{ detailData.maintainerPhone }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 验收负责人 </a-col>
            <span>{{ detailData.acceptorName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 联系方式 </a-col>
            <span>{{ detailData.acceptorPhone }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 问题照片 </a-col>
            <a-col :span="16">
              <a-row type="flex" justify="start">
                <img
                  @click="previewImg(item)"
                  v-for="(item, index) in faultPhotoUrlList"
                  :key="index"
                  :src="item"
                  style="width: 20%; height: 40px; margin-right: 4px"
                  alt=""
                />
                <!-- <img
                  :src="detailData.faultPhotoUrl"
                  style="width:20%;height:40px"
                  alt=""
                /> -->
              </a-row>
            </a-col>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 问题说明 </a-col>
            <span>{{ detailData.faultDescription }}</span>
          </a-row>
        </section>
        <!-- 待处理 -->
        <section class="m-t-18" v-if="drawerType == '0' && handStatus">
          <a-form :form="waitingHandForm">
            <a-form-item
              label="维保附件"
              :label-col="{ span: '8' }"
              :wrapper-col="{ span: '13', offset: '2' }"
            >
              <!-- {
                    rules: [
                      { required: true, message: '请上传问题说明' }
                    ]
                  } -->
              <!-- maintenanceAttach -->
              <a-row type="flex">
                <upload
                  style="display: inline-block; width: 100px"
                  v-decorator="[
                    'maintenanceAttach',
                    { rules: [{ required: false, message: '请上传照片' }] },
                  ]"
                  :node="{ type: 2, text: '上传' }"
                  :accept="['.png', '.jpg']"
                  :uploadUp="uploadUpMore"
                  :loadData="loadDataMaintenance"
                  @func="uploadMaintenanceFile"
                />
              </a-row>
            </a-form-item>
            <a-row class="fileList" style="padding: 0px 20px">
              <a v-if="MaintenanceFiles.length > 0" class="file-item m-r-10">
                <span
                  v-for="(item, index) in MaintenanceFiles"
                  :key="index"
                  class="m-r-4"
                  style="position: relative; display: inline-block"
                >
                  <a-icon
                    class="delFile"
                    type="close"
                    @click="delMaintenanceFile(item.url)"
                  />
                  <a-avatar shape="square" :size="40" :src="item.url" />
                </span>
                <!-- <a-avatar shape="square" :size="100" :src="files.url" /> -->
              </a>
            </a-row>
            <a-form-item
              label="维保备注"
              :label-col="{ span: '8' }"
              :wrapper-col="{ span: '13', offset: '2' }"
            >
              <a-input
                v-decorator="[
                  'maintenanceRemarks',
                  {
                    rules: [
                      { required: false },
                      { max: 200, message: '最大输入200字符' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-form>
        </section>

        <!-- 待验收 -->
        <section class="m-t-18" v-if="drawerType == '2' || drawerType == '1'">
          <a-row
            class="m-b-18"
            type="flex"
            justify="start"
            v-for="(item, index) in maintenanceAttachList"
            :key="index"
          >
            <a-col :span="8" style="color: #4b80cb">
              {{ index == "0" ? "维保附件" : "" }}
            </a-col>
            <a-col :span="14">
              <a-avatar
                @click="previewImg(item)"
                shape="square"
                :size="40"
                :src="item"
              />

              <!-- <previewImage :data="item" :size="40" /> -->

              <a @click="downLoadPng(item)" style="float: right">下载</a>
            </a-col>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 维保备注 </a-col>
            <a-col>
              <span>{{ detailData.maintenanceRemarks }}</span>
            </a-col>
          </a-row>
        </section>
        <!-- 验收意见 -->
        <section
          v-if="drawerType == '1' && checkStatus"
          class="m-t-18"
          style="border-top: 1px dashed"
        >
          <a-form :form="waitingCheckForm" class="m-b-18">
            <a-form-item
              label="验收意见"
              class="m-t-18"
              :label-col="{ span: '8' }"
              :wrapper-col="{ span: '13', offset: '2' }"
            >
              <a-radio-group
                name="radioGroup"
                v-decorator="[
                  'approve',
                  { rules: [{ required: true, message: '请选择验收状态' }] },
                ]"
              >
                <a-radio :value="1"> 通过 </a-radio>
                <a-radio :value="2"> 驳回 </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item
              label="验收备注"
              :label-col="{ span: '8' }"
              :wrapper-col="{ span: '13', offset: '2' }"
            >
              <a-input v-decorator="['acceptorRemarks']" />
            </a-form-item>
          </a-form>
        </section>
      </section>
      <!-- 进度详情 -->
      <section v-else>
        <a-row class="comeback">
          <span @click="comeback"> <a-icon type="left-circle" /> 进度详情</span>
        </a-row>

        <section class="m-t-24">
          <a-timeline>
            <a-timeline-item
              v-for="(item, index) in processData"
              :key="index"
              :color="item.processStatus == '2' ? 'red' : '#1890ff'"
            >
              <a-icon
                slot="dot"
                v-if="item.processStatus == '2'"
                type="close-circle"
                style="font-size: 12px; color: red"
              />
              <a-row>
                <a-col :span="6">
                  <span class="statusName">{{
                    item.processStatus == "0"
                      ? "提交"
                      : item.processStatus == "1"
                      ? "处理"
                      : item.processStatus == "2"
                      ? "验收驳回"
                      : "验收通过"
                  }}</span>
                </a-col>
                <a-col :span="16">
                  <span class="statusData"
                    >{{ item.personName }} {{ item.createTime }}</span
                  >
                </a-col>
              </a-row>
              <a-row
                class="m-t-10"
                v-if="item.processStatus == '2' || item.processStatus == '3'"
              >
                <a-col :span="6">
                  <span class="statusName">验收备注</span>
                </a-col>
                <a-col :span="16">
                  <span class="statusData">{{ item.acceptorRemarks }}</span>
                </a-col>
              </a-row>
            </a-timeline-item>
            <!-- <a-timeline-item>
              <a-row>
                <a-col :span="6">
                  <span class="statusName">处理</span>
                </a-col>
                <a-col :span="16">
                  <span class="statusData">张三2020/01/02 09:00</span>
                </a-col>
              </a-row>
            </a-timeline-item>
            <a-timeline-item color="red">
              <a-icon slot="dot" type="close-circle" style="font-size: 16px;" />

              <a-row>
                <a-col :span="6">
                  <span class="statusName">验收驳回</span>
                </a-col>
                <a-col :span="16">
                  <span class="statusData">张三2020/01/02 09:00</span>
                </a-col>
              </a-row>
              <a-row class="m-t-10">
                <a-col :span="6">
                  <span class="statusName">验收备注</span>
                </a-col>
                <a-col :span="16">
                  <span class="statusData"
                    >张三2020/01/02 09:00张三2020/01/02 09:00张三2020/01/02
                    09:00</span
                  >
                </a-col>
              </a-row>
            </a-timeline-item>
            <a-timeline-item>
              <a-row>
                <a-col :span="6">
                  <span class="statusName">处理</span>
                </a-col>
                <a-col :span="16">
                  <span class="statusData">张三2020/01/02 09:00</span>
                </a-col>
              </a-row>
            </a-timeline-item>

            <a-timeline-item>
              <a-row>
                <a-col :span="6">
                  <span class="statusName">验收通过</span>
                </a-col>
                <a-col :span="16">
                  <span class="statusData">张三2020/01/02 09:00</span>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="6">
                  <span class="statusName">验收备注</span>
                </a-col>
                <a-col :span="16">
                  <span class="statusData">张三2020/01/02 09:00</span>
                </a-col>
              </a-row>
            </a-timeline-item> -->
          </a-timeline>
        </section>
      </section>
      <div
        v-if="drawerTab == '1' && drawerType != '2'"
        :style="{
          position: 'absolute',
          right: '10px',
          bottom: '10px',
          width: '100%',
          padding: '20px 16px',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          v-if="handStatus || checkStatus"
          :style="{
            marginRight: '8px',
            backgroundColor: '#3f72ba',
            border: 'none',
          }"
          type="primary"
          @click="checkServiveForm"
        >
          提交
        </a-button>
        <a-button
          :style="{
            marginRight: '8px',
            backgroundColor: '#1c3d6c',
            color: '#fff',
            border: 'none',
          }"
          @click="close"
        >
          关闭
        </a-button>
      </div>
    </a-drawer>

    <!-- 选择人员模态框 -->
    <a-modal
      width="1080px"
      class="modal-container"
      v-model="selectPersonVisible"
      title="人员列表"
      :footer="false"
    >
      <singlePerson @ok="getSelectPerson" @cancel="cancelSelect" />
    </a-modal>

    <!-- 图片预览 -->
    <a-modal
      centered
      width="800px"
      class="modal-container"
      title="图片预览"
      v-model="previewImageModal.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-row class="text-center" type="flex" justify="center">
        <img
          style="background: #fff; width: 80%"
          :src="previewImageModal.url"
          alt=""
        />
      </a-row>
    </a-modal>
  </section>
</template>
<script>
import upload from "@/components/upload";
import singlePerson from "@/components/singlePerson";
import baseURL from "@/api/env";
// import {exprotFile} from '@/until/unit'
export default {
  data() {
    return {
      previewImageModal: {
        visible: false,
        url: "",
      },
      list: {
        pageNum: 1,
        total: 10,
        columns: [
          { title: "序号", dataIndex: "key", align: "center" },
          { title: "设备名称", dataIndex: "deviceName", align: "center" },
          { title: "发起人", dataIndex: "customerName", align: "center" },
          { title: "处理人", dataIndex: "maintainerName", align: "center" },
          { title: "验收人", dataIndex: "acceptorName", align: "center" },
          { title: "发起时间", dataIndex: "createTime", align: "center" },
          {
            title: "任务状态",
            dataIndex: "taskStatus",
            align: "center",
            scopedSlots: { customRender: "taskStatus" },
          },
          {
            title: "操作",
            dataIndex: "operation",
            align: "center",
            scopedSlots: { customRender: "operation" },
          },
        ],
        data: [],
      },
      visible: false,
      addServiceVisible: false,
      deviceVisible: false,
      show: true,
      drawerType: "", //维保进度类型
      drawerTitle: "", //标题
      drawerTab: "1",
      deviceName: "",
      endTime: "",
      startTime: "",
      taskStatus: "",
      sponsorTime: ["", ""], //发起时间
      files: [],
      selectPersonVisible: false,
      MaintenanceFiles: [],
      fileList: [],
      uploadUpMore: "uploadUpMore",
      loadDataArr: [],
      loadDataMaintenance: [],
      deviceData: [],
      serviveForm: this.$form.createForm(this),
      addserviveForm: this.$form.createForm(this),
      waitingHandForm: this.$form.createForm(this), //待处理
      waitingCheckForm: this.$form.createForm(this), //待验收
      fromDeviceId: "",
      maintainId: "", //维保任务id  待处理-提交id
      maintainerId: "", //维保负责人id
      acceptorId: "", //验收人id
      fromDeviceName: "",
      UserDropDownBox: [],
      deviceId: "",
      statusTitle: "",
      personType: "",
      detailData: {},
      processData: {},
      faultPhotoUrlList: [],
      maintenanceAttachList: [],
      handStatus: "",
      checkStatus: "",
    };
  },
  created() {
    this.getServiceDataList();
  },
  components: {
    upload,
    singlePerson,
  },
  computed: {
    userNickname() {
      return this.$store.state.userInfo.userNickname;
    },
    phone() {
      return this.$store.state.userInfo.phone;
    },
    userId() {
      return this.$store.state.userInfo.userId;
    },
  },
  methods: {
    // 下载,
    downLoadPng(url) {
      window.open(
        baseURL +
          "/personnel/upload/download.do?url=" +
          url +
          "&&exprotFile=维保附件",
        "_blank"
      );
    },
    // 图片预览
    previewImg(url) {
      this.previewImageModal.visible = true;
      this.previewImageModal.url = url;
    },
    // 下载图片
    downs() {},
    // 添加维保单的上传
    uploadFile(file) {
      this.files = file;
      console.log(file);
      this.loadDataArr = file;
      let uploadArr = [];
      file.forEach((item) => {
        uploadArr.push(item.url);
      });
      this.$nextTick(() => {
        this.addserviveForm.setFieldsValue({
          faultPhotoUrl: uploadArr.join(","),
        });
      });
    },
    // 添加_删除
    delFile(url) {
      // console.log(url,this.files);
      this.files = this.files.filter((item) => {
        if (url != item.url) return item;
      });
      this.loadDataArr = this.files;
      let uploadArr = [];
      this.loadDataArr.forEach((item) => {
        uploadArr.push(item.url);
      });
      console.log(uploadArr, "uploadArr");
      this.$nextTick(() => {
        this.addserviveForm.setFieldsValue({
          faultPhotoUrl: uploadArr.join(","),
        });
      });
    },

    // 待处理的上传
    uploadMaintenanceFile(file) {
      this.MaintenanceFiles = file;
      console.log(file);
      this.loadDataMaintenance = file;
      let uploadArr = [];
      file.forEach((item) => {
        uploadArr.push(item.url);
      });
      this.$nextTick(() => {
        this.waitingHandForm.setFieldsValue({
          maintenanceAttach: uploadArr.join(","),
        });
      });
    },
    // 待处理_删除
    delMaintenanceFile(url) {
      // console.log(url,this.files);
      this.MaintenanceFiles = this.MaintenanceFiles.filter((item) => {
        if (url != item.url) return item;
      });
      this.loadDataMaintenance = this.MaintenanceFiles;
      let uploadArr = [];
      this.loadDataMaintenance.forEach((item) => {
        uploadArr.push(item.url);
      });
      this.$nextTick(() => {
        this.waitingHandForm.setFieldsValue({
          maintenanceAttach: uploadArr.join(","),
        });
      });
    },
    // 账号下拉
    queryUserDropDownBox() {
      this.$api.queryUserDropDownBox().then((res) => {
        if (res.code == "200") {
          console.log(res);
          this.UserDropDownBox = res.data;
        }
      });
    },
    // 查询设备列表
    getDevice() {
      this.$api.getDevice().then((res) => {
        if (res.code == "200") {
          // console.log(res.data);
          this.deviceData = res.data ? res.data : [];
        }
      });
    },
    // 下拉选择设备
    getDeviceData(item) {
      this.fromDeviceName = item.deviceName;
      this.fromDeviceId = item.id;
    },
    // 维保任务列表
    getServiceDataList() {
      let params = {
        current: this.list.pageNum,
        deviceName: this.deviceName,
        startTime: this.startTime,
        endTime: this.endTime,
        taskStatus: this.taskStatus,
      };
      this.$api.getServiceDataList(params).then((res) => {
        if (res.code == "200") {
          console.log(res.data.records, "records");
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.key = index + 1;
            });
          }
          this.list.total = res.data.total;
          this.list.data = res.data.records;
        }
      });
    },

    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.getServiceDataList();
    },
    // 查询维保设备列表
    submitServiveForm(e) {
      e && e.preventDefault();
      let formVal = this.serviveForm.getFieldsValue();
      console.log(formVal);
      this.deviceName = formVal.deviceName;
      this.taskStatus = formVal.taskStatus;
      this.list.pageNum = 1;
      this.startTime = this.sponsorTime[0]
        ? this.sponsorTime[0].format("YYYY-MM-DD")
        : "";
      this.endTime = this.sponsorTime[1]
        ? this.sponsorTime[1].format("YYYY-MM-DD")
        : "";
      this.getServiceDataList();
    },
    // 重置维保设备列表
    reset() {
      this.deviceName = "";
      this.taskStatus = "";
      this.list.pageNum = 1;
      this.startTime = "";
      this.endTime = "";
      this.sponsorTime = ["", ""];
      this.serviveForm.resetFields();
      this.getServiceDataList();
    },

    //添加维保单
    addService() {
      this.getDevice();
      this.queryUserDropDownBox();
      console.log(this.$store.state.userInfo.phone, "phone");
      this.addServiceVisible = true;
      this.$nextTick(() => {
        this.addserviveForm.setFieldsValue({
          acceptorName: this.$store.state.userInfo.userNickname,
          acceptorPhone: this.$store.state.userInfo.phone,
        });
        this.acceptorId = this.$store.state.userInfo.userId;
      });
    },
    // 查看维保单
    checkDeviceStatus(item) {
      console.log(item);
      this.maintainId = item.id; //待处理-提交id

      //待处理提交id
      // this.maintenanceTaskId=item.maintenanceTaskId
      this.$api.deviceMaintenance({ id: item.id }).then((res) => {
        if (res.code == "200") {
          // 维保单详情
          this.detailData = res.data ? res.data : {};
          console.log(this.detailData, "detailData");
          this.faultPhotoUrlList = this.detailData.faultPhotoUrl
            ? this.detailData.faultPhotoUrl.split(",")
            : "";

          this.maintenanceAttachList = this.detailData.maintenanceAttach
            ? this.detailData.maintenanceAttach.split(",")
            : "";
        }
      });
      this.drawerType = item.taskStatus;
      console.log('===',this.$store.state);
      // 判断处理操作权限
      if (item.taskStatus == "0" && item.maintainerName == this.userNickname) {
        this.handStatus = true;
      } else {
        this.handStatus = false;
      }

      // 判断验收操作权限
      if (item.taskStatus == "1" && item.acceptorName == this.userNickname) {
        this.checkStatus = true;
      } else {
        this.checkStatus = false;
      }

      this.drawerTitle =
        item.taskStatus == "0"
          ? "维保处理"
          : item.taskStatus == "1"
          ? "维保验收"
          : "维保详情";
      this.statusTitle =
        item.taskStatus == "0"
          ? "待处理"
          : item.taskStatus == "1"
          ? "待验收"
          : "已完成";
      this.deviceVisible = true;
    },
    // 切换进度详情
    checkProcess() {
      this.drawerTab = "2";
      console.log(this.maintainId, "checkProcess");
      this.$api.processDetail({ id: this.maintainId }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.processData = res.data ? res.data : {};
        }
      });
    },
    // 返回进度详情
    comeback() {
      this.drawerTab = "1";
      //console.log(this.drawerTab);
    },
    // 待处理 待验收 已完成维保单提交 waitingHandForm
    checkServiveForm() {
      // 待处理
      if (this.drawerType == "0") {
        this.waitingHandForm.validateFields((err, values) => {
          if (!err) {
            console.log(values);
            // 待处理

            let params = {
              maintenanceRemarks: values.maintenanceRemarks,
              maintenanceAttach: values.maintenanceAttach,
              maintenanceTaskId: this.maintainId,
              maintainerId: this.userId,
              maintainerName: this.userNickname,
              maintainerPhone: this.phone,
            };
            console.log("待处理", params);
            this.$api.approveDevice(params).then((res) => {
              if (res.code == "200") {
                this.deviceVisible = false;
                this.getServiceDataList();
              }
            });
          }
        });
      }

      // 待验收
      if (this.drawerType == "1") {
        this.waitingCheckForm.validateFields((err, values) => {
          if (!err) {
            console.log(values);
            // 通过
            if (values.approve == "1") {
              console.log("通过");
              let params = {
                maintenanceTaskId: this.maintainId,
                acceptorRemarks: values.acceptorRemarks,
                acceptorId: this.userId,
                acceptorName: this.userNickname,
                acceptorPhone: this.phone,
              };
              this.$api.approveDevice(params).then((res) => {
                if (res.code == "200") {
                  this.deviceVisible = false;
                  this.getServiceDataList();
                }
              });
            }
            // 驳回
            if (values.approve == "2") {
              console.log("驳回 rejectDevices");
              let params = {
                maintenanceTaskId: this.maintainId,
                acceptorRemarks: values.acceptorRemarks,
                acceptorId: this.userId,
                acceptorName: this.userNickname,
                acceptorPhone: this.phone,
              };
              this.$api.rejectDevices(params).then((res) => {
                if (res.code == "200") {
                  this.deviceVisible = false;
                  this.getServiceDataList();
                }
              });
            }
          }
        });
      }
    },
    // 保存维保单
    saveServiveForm() {
      // let formVal = this.addserviveForm.getFieldsValue();

      this.addserviveForm.validateFields((err, values) => {
        if (!err) {
          // console.log(values);
          let params = {
            acceptorId: this.acceptorId, //验收人id this.acceptorId
            acceptorName: values.acceptorName, //验收人姓名
            acceptorPhone: values.acceptorPhone, //验收人电话
            customerName: this.userNickname, //发起人姓名
            customerPhone: this.phone, //发起人电话
            deviceId: this.fromDeviceId,
            deviceName: this.fromDeviceName,
            faultDescription: values.faultDescription, //问题描述
            faultPhotoUrl: values.faultPhotoUrl, //照片 values.faultPhotoUrl
            maintainerId: this.maintainerId, // 维保负责人id
            maintainerName: values.maintainerName, // 维保负责人名字
            maintainerPhone: values.maintainerPhone, // 维保负责人电话
          };
          console.log(params);
          this.$api.addServiceData(params).then((res) => {
            if (res.code == "200") {
              this.addServiceVisible = false;
              this.files = []; //清空图片

              this.getServiceDataList();
            }
          });
        }
      });
    },
    close() {
      this.files = [];
      this.visible = false;
      this.addServiceVisible = false;
      this.deviceVisible = false;
      this.drawerTab = "1"; //恢复进度切换页面
    },
    // 关闭
    onClose() {
      this.files = [];
      this.visible = false;
      this.addServiceVisible = false;
      this.deviceVisible = false;
      this.drawerTab = "1"; //恢复进度切换页面
    },
    // 选择维保负责人
    selectPerson(type) {
      this.personType = type; //1.维保负责人 2.验收负责人
      this.selectPersonVisible = true;
    },
    // 保存维保负责人
    getSelectPerson(list) {
      console.log(list, this.personType);

      this.selectPersonVisible = false;
      if (this.personType == "1") {
        this.$nextTick(() => {
          this.addserviveForm.setFieldsValue({
            maintainerName: list.name,
            maintainerPhone: list.phone,
          });
          this.maintainerId = list.id;
        });
      } else {
        this.$nextTick(() => {
          this.addserviveForm.setFieldsValue({
            acceptorName: list.name,
            acceptorPhone: list.phone,
          });
          this.acceptorId = list.id;
        });
      }
    },
    // 取消维保负责人
    cancelSelect() {
      this.selectPersonVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/.ant-btn-sm {
  height: 28px;
}
/deep/.ant-upload-list-item-card-actions .anticon {
  color: #1890ff !important;
}
.deviceData {
  padding-bottom: 30px;
  border-bottom: 1px dashed;
}
/deep/.ant-upload-list-item-info:hover {
  background-color: #1c3d6c !important;
}
/deep/.ant-radio-wrapper {
  color: #1890ff;
}
/deep/.anticon-paper-clip {
  color: #1890ff !important;
}
/deep/.ant-form-item {
  margin-bottom: 20px;
}
/deep/.ant-timeline-item-head {
  background: transparent;
}
.comeback {
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  border-bottom: 1px dashed;
  padding-bottom: 10px;
}
.statusName {
  color: rgb(75, 128, 203);
}
.statusData {
  color: #fff;
}
.fileList {
  .delFile {
    position: absolute;
    color: #fff;

    right: -1px;
    z-index: 3;
  }
}
.process {
  position: relative;
  right: -236px;
  top: -12px;
}
</style>